import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import mixin from "./mixin";
import { createI18n } from "vue-i18n";
import LoaderPlugin from "./plugins/loaderPlugin";
import { FontAwesomeIcon } from "./plugins/font-awesome";

const defaultLocale = localStorage.getItem("locale") || "en"; // Get the stored locale or default to 'en'
const i18n = createI18n({
  locale: defaultLocale,
  messages: {
    en: require("./locales/en.json"),
    ar: require("./locales/ar.json"),
  },
});

// createApp(App).use(store).use(router).component("font-awesome-icon", FontAwesomeIcon).mount('#app')
/* eslint-disable */
var app = createApp(App)
  .use(store)
  .use(router)
  .use(LoaderPlugin)
  .use(i18n)
  .mixin(mixin)
  .component("font-awesome-icon", FontAwesomeIcon);
app.directive("select", {
  twoWay: true,
  bind: function (el, binding, vnode) {
    $(el)
      .select2()
      .on("select2:select", (e) => {
        el.dispatchEvent(new Event("change", { target: e.target }));
      });
  },
  componentUpdated: function (el, me) {
    $(el).trigger("change");
  },
});
app.mount("#app");

// using for Vue Router Page Title show
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || "Vue Router Page Title Example";
  next();
});
