import { addScript, removeScript } from "@/utils/globalFunctions";
import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    name: "guest",
    path: "/login",
    component: () => import("../components/layouts/Guest.vue"),
    meta: {
      middleware: "guest",
      roles: ["auth"],
    },
    children: [
      {
        path: "",
        name: "login",
        component: () =>
          import(/*webpackChunck: "home" */ "../pages/Auth/LoginView.vue"),
        meta: {
          title: `Log In`,
        },
      },
      {
        path: "/reset-password",
        name: "resetPassword",
        component: () =>
          import(
            /*webpackChunck: "home" */ "../pages/Auth/resetPasswordView.vue"
          ),
        meta: {
          title: `Passwsord Reset`,
        },
      },
    ],
  },
  {
    name: "base",
    path: "/",
    component: () => import("../components/layouts/Default.vue"),
    meta: {
      middleware: "auth",
      requiresAuth: true,
      roles: ["1"],
    },
    children: [
      {
        path: "/dashboard",
        name: "home",
        component: () =>
          import(/* webpackChunkName: "home" */ "../pages/Admin/HomeView.vue"),
        meta: {
          title: `Dashboard`,
        },
      },
      {
        path: "add-affiliate",
        name: "addaffiliate",
        component: () =>
          import(
            /* webpackChunkName: "addaffiliate" */ "../pages/Admin/Affiliate/AddAffiliateView.vue"
          ),
        meta: {
          title: `Add affiliate`,
        },
      },
      {
        path: "edit-affiliate/:id",
        name: "editaffiliate",
        component: () =>
          import(
            /* webpackChunkName: "editaffiliate" */ "../pages/Admin/Affiliate/EditAffiliateView.vue"
          ),
        meta: {
          title: `Edit Reseller`,
        },
      },
      {
        path: "affiliate-lists",
        name: "affiliateLists",
        component: () =>
          import(
            /* webpackChunkName: "affiliatelist" */ "../pages/Admin/Affiliate/AffiliateListView.vue"
          ),
        meta: {
          title: `Affiliate List`,
        },
      },
      {
        path: "affiliate-lists-web-request",
        name: "affiliateListsFromWebRequest",
        component: () =>
          import(
            /* webpackChunkName: "affiliatelist" */ "../pages/Admin/Affiliate/AffiliateListFromWebView.vue"
          ),
        meta: {
          title: `Affiliate List`,
        },
      },
      {
        path: "affiliate-productlist/:id",
        name: "affiliateproductLists",
        component: () =>
          import(
            /* webpackChunkName: "affiliateproductLists" */ "../pages/Admin/Affiliate/AffiliateProductListView.vue"
          ),
        meta: {
          title: `Affiliate Product List`,
        },
      },
      {
        path: "add-coupon-affiliate/:id",
        name: "addCoupontoaffiliate",
        component: () =>
          import(
            /* webpackChunkName: "addcoupontoaffiliate" */ "../pages/Admin/Affiliate/AddCouponToAffiliateView.vue"
          ),
        meta: {
          title: `Add Coupon To Affiliate`,
        },
      },
      {
        path: "add-product",
        name: "addproduct",
        component: () =>
          import(
            /* webpackChunkName: "addproduct" */ "../pages/Admin/Product/AddProductView.vue"
          ),
        meta: {
          title: `Add Product`,
        },
      },
      {
        path: "edit-product/:id",
        name: "editproduct",
        component: () =>
          import(
            /* webpackChunkName: "editproduct" */ "../pages/Admin/Product/AddProductView.vue"
          ),
        meta: {
          title: `Add Product`,
        },
      },
      {
        path: "product-lists",
        name: "productLists",
        component: () =>
          import(
            /* webpackChunkName: "productlist" */ "../pages/Admin/Product/ProductListView.vue"
          ),
        meta: {
          title: `Product List`,
        },
      },
      {
        path: "add-coupon",
        name: "addcoupon",
        component: () =>
          import(
            /* webpackChunkName: "addcoupon" */ "../pages/Admin/Coupon/AddCouponView.vue"
          ),
        meta: {
          title: `Add Coupon`,
        },
      },
      {
        path: "edit-coupon/:id",
        name: "editcoupon",
        component: () =>
          import(
            /* webpackChunkName: "editcoupon" */ "../pages/Admin/Coupon/AddCouponView.vue"
          ),
        meta: {
          title: `Edit Coupon`,
        },
      },
      {
        path: "coupon-lists",
        name: "couponlists",
        component: () =>
          import(
            /* webpackChunkName: "addcoupon" */ "../pages/Admin/Coupon/CouponListView.vue"
          ),
        meta: {
          title: `Add Coupon`,
        },
      },
      {
        path: "panel-settings",
        name: "addpanelsettings",
        component: () =>
          import(
            /* webpackChunkName: "Addpanelsettings" */ "../pages/Admin/Settings/AddPanelSettingView.vue"
          ),
        meta: {
          title: `Settings`,
        },
      },
      {
        path: "currency-settings",
        name: "addCurrencySettings",
        component: () =>
          import(
            /* webpackChunkName: "addCurrencysettings" */ "../pages/Admin/Settings/AddCurrencySettingView.vue"
          ),
        meta: {
          title: `Settings`,
        },
      },
      {
        path: "change-password",
        name: "changePassword",
        component: () =>
          import(
            /* webpackChunkName: "addCurrencysettings" */ "../pages/Admin/Settings/PasswordSettingView.vue"
          ),
        meta: {
          title: `Change Password`,
        },
      },
      {
        path: "sales-reports",
        name: "salesreports",
        component: () =>
          import(
            /* webpackChunkName: "salesreports" */ "../pages/Admin/Reports/SalesReportsView.vue"
          ),
        meta: {
          title: `Sales Report`,
        },
      },
      {
        path: "payment-reports",
        name: "paymentreports",
        component: () =>
          import(
            /* webpackChunkName: "paymentreports" */ "../pages/Admin/Reports/PaymentsReportsView.vue"
          ),
        meta: {
          title: `Payment Reports`,
        },
      },
      {
        path: "add-api-hooks",
        name: "addapihooks",
        component: () =>
          import(
            /* webpackChunkName: "addapihooks" */ "../pages/Admin/Settings/AddApiHooksView.vue"
          ),
        meta: {
          title: `Settings`,
        },
      },
      {
        path: "withdrawal-request",
        name: "withdrawalrequest",
        component: () =>
          import(
            /* webpackChunkName: "withdrawalrequest" */ "../pages/Admin/Withdrawal/WithdrawalRequestView.vue"
          ),
        meta: {
          title: `Withdrawal Request List`,
        },
      },
      {
        path: "banner-list",
        name: "BannerList",
        component: () =>
          import(
            /* webpackChunkName: "BannerList" */ "../pages/Admin/Banner/BannerList.vue"
          ),
        meta: {
          title: `Banner List`,
        },
      },
      {
        path: "api-doc",
        name: "apidocs",
        component: () =>
          import(
            /* webpackChunkName: "apidocs" */ "../pages/Admin/Settings/ApidocsView.vue"
          ),
        meta: {
          title: `Api Documentation`,
        },
      },
    ],
  },
  {
    name: "affiliate",
    path: "/",
    component: () => import("../components/layouts/Affiliate.vue"),
    meta: {
      middleware: "auth",
      requiresAuth: true,
      roles: ["2"],
    },
    children: [
      {
        path: "/affiliate-dashboard",
        name: "affiliateDashboard",
        component: () => import("../pages/Affiliator/DashboardView.vue"),
        meta: {
          title: `Affiliate Dashboard`,
        },
      },
      {
        path: "/send-withdraw-request",
        name: "affwithdrawrequest",
        component: () =>
          import("../pages/Affiliator/Withdraw/SendWithdrawRequestView.vue"),
        meta: {
          title: `Affiliate Withdraw Request`,
        },
      },
      {
        path: "/withdraw-list",
        name: "affwithdrawlist",
        component: () =>
          import("../pages/Affiliator/Withdraw/WithdrawalListView.vue"),
        meta: {
          title: `Affiliate Withdraw List`,
        },
      },
      {
        path: "/affiliate-sales-reports",
        name: "affsalesreport",
        component: () =>
          import("../pages/Affiliator/Report/SalesReportView.vue"),
        meta: {
          title: `Affiliate Sales Report`,
        },
      },
      {
        path: "/affiliate-payment-reports",
        name: "affpaymentreport",
        component: () =>
          import("../pages/Affiliator/Report/PaymentReportView.vue"),
        meta: {
          title: `Affiliate Payment Report`,
        },
      },
      {
        path: "changepassword",
        name: "Passwordchange",
        component: () =>
          import(
            /* webpackChunkName: "addCurrencysettings" */ "../pages/Admin/Settings/PasswordSettingView.vue"
          ),
        meta: {
          title: `Change Password`,
        },
      },
      {
        path: "banner-lists",
        name: "BannerLists",
        component: () =>
          import(
            /* webpackChunkName: "BannerList" */ "../pages/Admin/Banner/BannerList.vue"
          ),
        meta: {
          title: `Banner List`,
        },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("../components/404NotFound.vue"),
    meta: {
      title: "Page Not Found",
    },
  },
];

const router = createRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem("jwt_token") !== null;
  let isAffiliate = "";

  if (localStorage.getItem("user")) {
    const user = JSON.parse(localStorage.getItem("user"));
    isAffiliate = user.user.user_type;
  }

  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (isAuthenticated) {
    addScript();
  } else {
    removeScript();
  }

  if (requiresAuth && !isAuthenticated) {
    next("/login");
  } else if (
    isAuthenticated &&
    to.meta.roles &&
    to.meta.roles.includes("auth")
  ) {
    if (isAffiliate === "1") {
      next("/dashboard");
    } else if (isAffiliate === "2") {
      next("/affiliate-dashboard");
    } else {
      next();
    }
  } else if (
    isAuthenticated &&
    to.meta.roles &&
    !to.meta.roles.includes(isAffiliate)
  ) {
    if (isAffiliate === "1") {
      next("/dashboard");
    } else if (isAffiliate === "2") {
      next("/affiliate-dashboard");
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
