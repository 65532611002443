export const getHumanReadableDate = (date) => {
  let dateString = new Date(date);
  const options = { year: "numeric", month: "long", day: "numeric" };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

export const getLength = (obj) => {
  return Object.keys(obj).length;
};

export const com_DecryptString = (string, key = "") => {
  if (key == "") {
    key = process.env.VUE_APP_ENCRYPTIONKEY;
  }

  let str = atob(string);
  var output = "";
  for (var i = 0; i < str.length; ) {
    for (var j = 0; j < key.length && i < str.length; j++, i++) {
      output += String.fromCharCode(
        str[i].charCodeAt(0) ^ key[j].charCodeAt(0)
      );
    }
  }
  return output;
};

export const com_EncryptString = (string, key = "") => {
  if (key == "") {
    key = process.env.VUE_APP_ENCRYPTIONKEY;
  }

  let str = string.toString();
  var output = "";
  for (var i = 0; i < str.length; ) {
    for (var j = 0; j < key.length && i < str.length; j++, i++) {
      output += String.fromCharCode(
        str[i].charCodeAt(0) ^ key[j].charCodeAt(0)
      );
    }
  }
  return btoa(output);
};

export function addScript() {
  if (!document.getElementById("hs-script-loader")) {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.id = "hs-script-loader";
    script.async = true;
    script.defer = true;
    script.src = "https://js-eu1.hs-scripts.com/144273536.js";
    document.body.appendChild(script);
  }
}

export function removeScript() {
  const script = document.getElementById("hs-script-loader");
  if (script) {
    script.remove();
  }
}

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const validateEmail = (email) => {
  let emailErrorMessage = "";
  var emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  var specialChars = /[`!#$%^&*()_+\-=\\|,<>?~]/;

  if (!emailPattern.test(email)) {
    emailErrorMessage = "Invalid email address.";
  } else if (specialChars.test(email)) {
    emailErrorMessage = "Email should not contain special characters.";
  } else {
    emailErrorMessage = "";
  }
  return emailErrorMessage;
};

export const formatAMPM = (date) => {
  let dateObject = new Date(date);
  const options = { hour: "2-digit", minute: "2-digit", hour12: true };
  return dateObject.toLocaleTimeString(undefined, options);
};

export const com_currentDateRange = (addition_days = 0) => {
  //Current Date
  const current = new Date();
  const startDate =
    current.getFullYear() +
    "-" +
    ("0" + (current.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + current.getDate()).slice(-2);

  //Last Date
  var lastDate = new Date();
  if (parseInt(addition_days) != 0) {
    lastDate.setDate(lastDate.getDate() + parseInt(addition_days));
  }

  const endDate =
    lastDate.getFullYear() +
    "-" +
    ("0" + (lastDate.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + lastDate.getDate()).slice(-2);

  if (endDate < startDate) {
    var dateArr = { startDate: endDate, endDate: startDate };
  } else {
    dateArr = { startDate: startDate, endDate: endDate };
  }

  return dateArr;
};

export const com_verifyAllowOrNotSetting = () => {
  return true;
};
