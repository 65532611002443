import axiosInstance from "@/plugins/HTTP";


class AuthService {
  //Layer
  login(user) {
    return axiosInstance
      .post("login", {
        login: user.username,
        password: user.password,
      })
      .then((response) => {
        if (response.status) {
          localStorage.setItem("user", JSON.stringify(response.data.message));
        }
        return response;
      });
  }
}

export default new AuthService();
