<template>
  <div @mousedown="com_SetLastActivityTime">
    <router-view />
  </div>
</template>

<script>
export default {
  mounted() {
    if (this.isLogin) {
      this.addScript();
    }
  },
  computed: {
    isRtl() {
      return this.$i18n.locale === "ar";
    },
    isLogin() {
      return localStorage.getItem("jwt_token") !== null;
    },
  },
  watch: {
    isLogin(newVal) {
      if (newVal) {
        this.addScript();
      } else {
        this.removeScript();
      }
    },
  },
  methods: {
    addScript() {
      if (!document.getElementById("hs-script-loader")) {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.id = "hs-script-loader";
        script.async = true;
        script.defer = true;
        script.src = "https://js-eu1.hs-scripts.com/144273536.js";
        document.body.appendChild(script);
      }
    },
    removeScript() {
      const script = document.getElementById("hs-script-loader");
      if (script) {
        script.remove();
      }
    },
  },
};
</script>
