import axiosInstance from "@/plugins/HTTP";
import authHeader from "./auth-header";

class AdminService {
  getProfile() {
    return axiosInstance.get("profile", {
      headers: authHeader(),
    });
  }
  addOrEditAffiliate(querystr, form) {
    return axiosInstance.post("affiliate" + querystr, form, {
      headers: authHeader(),
    });
  }
  getCountries() {
    return axiosInstance.get("get-countries", {
      headers: authHeader(),
    });
  }

  getAffiliateListByQueryStr(querystr) {
    return axiosInstance.get("affiliate?" + querystr, {
      headers: authHeader(),
    });
  }

  getAffiliateListFromWebByQueryStr(querystr) {
    return axiosInstance.get("affiliate-from-web?" + querystr, {
      headers: authHeader(),
    });
  }

  editSingleAffiliate(id) {
    return axiosInstance.get("affiliate/" + id, {
      headers: authHeader(),
    });
  }

  AffiliateStatusUpdate(id, form) {
    return axiosInstance.post("affiliate/" + id, form, {
      headers: authHeader(),
    });
  }

  BannerStatusUpdate(form) {
    return axiosInstance.post("banner-status", form, {
      headers: authHeader(),
    });
  }

  NotificationStatusUpdate(form) {
    return axiosInstance.post("update-notification-status", form, {
      headers: authHeader(),
    });
  }

  getAffiliateProduct(id) {
    return axiosInstance.get("affiliate-wise-products/" + id, {
      headers: authHeader(),
    });
  }
  getAffiliateCoupon(id) {
    return axiosInstance.get("affiliate-wise-coupons/" + id, {
      headers: authHeader(),
    });
  }
  addOrEditProduct(querystr, form) {
    return axiosInstance.post("product" + querystr, form, {
      headers: authHeader(),
    });
  }
  editSingleProduct(id) {
    return axiosInstance.get("product/" + id, {
      headers: authHeader(),
    });
  }

  getProductListByQueryStr(querystr) {
    return axiosInstance.get("product?" + querystr, {
      headers: authHeader(),
    });
  }

  ProductStatusUpdate(id, form) {
    return axiosInstance.post("product/" + id, form, {
      headers: authHeader(),
    });
  }
  addOrEditCoupon(querystr, form) {
    return axiosInstance.post("coupon" + querystr, form, {
      headers: authHeader(),
    });
  }
  editSingleCoupon(id) {
    return axiosInstance.get("coupon/" + id, {
      headers: authHeader(),
    });
  }
  getCouponListByQueryStr(querystr) {
    return axiosInstance.get("coupon?" + querystr, {
      headers: authHeader(),
    });
  }
  CouponStatusUpdate(id, form) {
    return axiosInstance.post("coupon/" + id, form, {
      headers: authHeader(),
    });
  }
  updatePannelSetting(id, form) {
    return axiosInstance.post("update-panel-setting/" + id, form, {
      headers: authHeader(),
    });
  }
  getPanelSetting() {
    return axiosInstance.get("panel-setting", {
      headers: authHeader(),
    });
  }
  getCurrency() {
    return axiosInstance.get("get-currency", {
      headers: authHeader(),
    });
  }
  EditCurrency(form) {
    return axiosInstance.post("update-currency", form, {
      headers: authHeader(),
    });
  }
  saveBanner(form) {
    return axiosInstance.post("banners", form, {
      headers: authHeader(),
    });
  }
  changePassword(form) {
    return axiosInstance.post("change-password", form, {
      headers: authHeader(),
    });
  }
  resetPassword(form) {
    return axiosInstance.post("reset-password", form);
  }
  addProductCoupon(form) {
    return axiosInstance.post("coupon-bundle-details", form, {
      headers: authHeader(),
    });
  }
  addCouponAffiliate(form) {
    return axiosInstance.post("affiliate_wise_coupon", form, {
      headers: authHeader(),
    });
  }
  addCouponToAffiliate(form) {
    return axiosInstance.post("assign-coupon", form, {
      headers: authHeader(),
    });
  }
  changeCouponToAffiliate(form) {
    return axiosInstance.post("change-coupon", form, {
      headers: authHeader(),
    });
  }
  addOrEditCallbackUrl(form) {
    return axiosInstance.post("add-callback-url", form, {
      headers: authHeader(),
    });
  }
  getRequestListByQueryStr(querystr) {
    return axiosInstance.get("withdrawal-list?" + querystr, {
      headers: authHeader(),
    });
  }
  getBanners() {
    return axiosInstance.get("banners", {
      headers: authHeader(),
    });
  }
  RequestStatusUpdate(id, form) {
    return axiosInstance.post("withdrawal-request/" + id, form, {
      headers: authHeader(),
    });
  }
  searchReportData(form) {
    return axiosInstance.post("product-sales-report", form, {
      headers: authHeader(),
    });
  }
  addSalesReport(form) {
    return axiosInstance.post("product-sales-report", form, {
      headers: authHeader(),
    });
  }
  searchPayReportData(form) {
    return axiosInstance.post("withdraw-request-report", form, {
      headers: authHeader(),
    });
  }
  addPaymentReport(form) {
    return axiosInstance.post("withdraw-request-report", form, {
      headers: authHeader(),
    });
  }

  getDashboardData() {
    return axiosInstance.get("get-dashboard-data", {
      headers: authHeader(),
    });
  }

  getWithDrawDetails(id) {
    return axiosInstance.get("get-withdrawal-request-details/" + id, {
      headers: authHeader(),
    });
  }
  getNotifications() {
    return axiosInstance.get("get-notifications", {
      headers: authHeader(),
    });
  }
  deleteBanner(id) {
    return axiosInstance.delete(`banners/${id}`, {
      headers: authHeader(),
    });
  }
  downloadBanner(id, lang) {
    return axiosInstance.get(`banners/${id}/${lang}`, {
      headers: authHeader(),
    });
  }
}

export default new AdminService();
